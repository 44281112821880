"use client";
import type { NextPage } from "next";
import NotFoundScreen from "../components/Screens/NoOfferScreens/NotFoundScreen/NotFoundScreen";
import { wrapper } from "./store/store";
import { setMinHeight } from "./store/layoutSlice";

const Page404: NextPage = () => {
    return <NotFoundScreen />;
};

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
    await store.dispatch(setMinHeight(100));
    return {
        props: {}
    };
});

export default Page404;